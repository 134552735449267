$color-primary: #00b894;
$text-primary: #fff;
$text-terciary: #aaaaaa;

:root {
  // colors
  --color-primary: #008241;
  --primary-light: #4b9f37;
  --tempos-color: #3570bd;
  --tempos-alternative-color: #162c9b;

  --green-light: #80c470;
  --green-dark: #2d4040;

  --text-plain: #464a4e;
  --text-primary: #fff;
  --text-terciary: #aaaaaa;
  --paragraph-color: #848484;

  --grey-dark: rgba(0, 0, 0, 0.7);
  --grey-light: #808080;

  --orange-primary: #fb6b32;

  --yellow-primary: #ffe86f;

  // fonts
  --title-font: 700 3rem Nunito;
  --sub-title-font: 400 2rem Nunito;
  --regular-font: 400 1.6rem Nunito;

  --body: 400 1.4rem Roboto;

  // others
  --default-shaddow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  --smooth-transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
