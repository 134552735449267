.container-ListPolosPadin {
    padding: 10px;
    margin-left: 5px;
    margin-top: -15px;

    .ant-collapse {
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        padding: 7px 9px;
    }
    .registro-acao-container .steps-container p {
        font-size: 13px;
    }
}

