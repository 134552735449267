@import '~antd/dist/antd.less';

.leaflet-popup-content-wrapper {
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.25);
}

.ant-modal-body {
  padding: 24px 24px 0 24px;
}

.ant-modal-footer {
  display: none;
}

@primary-color: #33a02c;@secondary-color: #00B894;@link-color: #52DF9A;@success-color: #00b894;@link-hover-color: #00B894;@border-radius-base: 2px;@input-icon-color: #52DF9A;@menu-bg: #f2f2f2;@menu-item-active-bg: #f2f2f2;@menu-item-color: #00B894;@menu-item-hover-bg: #f2f2f2;@btn-primary-bg: #52DF9A;@slider-track-background-color: #f2f2f2;@layout-header-background: #f2f2f2;@input-icon-hover-color: #00B894;@drawer-bg: #f2f2f2;@error: #FF7675;