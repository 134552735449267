@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import './global-variables.scss';
// @import '~cegov-antd/dist/index.css';
@font-face {
  font-family: Isidora Sans;
  src: url('../assets/fonts/isidora-sans.ttf');
}

:root {
  font-size: 60%;

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 67.5%;
    }
  }
}

.container-box {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  font-family: Nunito;
}

html {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

input {
  outline: 0;
}

ul {
  list-style: none;
}

body,
input,
button {
  font-weight: 500;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4 {
  // color: #fff;
  margin: 0;
}
.ant-input:hover {
  border-color: var(--color-primary);
}
.ant-modal-body {
  padding: 24px 24px 24px 24px;
}

.ant-modal-footer {
  display: none;
}

.menu-options {
  width: 103px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 28px;

  img {
    height: 76px;
    width: 76px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.selected-image {
  img {
    border-radius: 4px;
  }
}

.semantic-button {
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}
